import { memo, useCallback, useEffect, useState } from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Center,
    Flex,
    Switch,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { QueryFilter } from "../../api/claimsInvoicingApi";
import { ClaimParams, DEFAULT_QUERY_FILTER } from "../../api/claimsApi";
import { Search } from "../../components/Search";
import { BranchCompanyModal } from "./modals/BranchCompanyModal";
import { HierarchicalDto, useGetHierarchyQuery } from "../../api/usersApi";
import BranchCompany from "../../components/BranchCompany";
import { useFeature } from "flagged";

type ClaimsSearchProps = {
    isClaimsSearchError: boolean;
    isFetchingClaims: boolean;
    getClaimsByCompanyId: (claimParams: ClaimParams) => void;
    claimsByCompanyIdArgs: ClaimParams | undefined;
    resetPagination: () => void;
};

const ClaimsSearch = memo(function ClaimsSearch({
    isClaimsSearchError,
    isFetchingClaims,
    getClaimsByCompanyId,
    claimsByCompanyIdArgs,
    resetPagination
}: ClaimsSearchProps) {
    const { t } = useTranslation("claimsInvoicesOverview");

    const [showClosedClaims, setShowClosedClaims] = useState<boolean>(false);
    const [queryFilter, setQueryFilter] = useState<QueryFilter>({
        ...DEFAULT_QUERY_FILTER,
        query: claimsByCompanyIdArgs?.params?.query
    });
    const [selectedBranch, setSelectedBranch] = useState<HierarchicalDto | null>(null);
    const { data: branchCompanies, isLoading, isError } = useGetHierarchyQuery("");

    const isShowBranchCompanyModalEnabled = useFeature("CIA-322-HierarchicalCompanies") as boolean;

    const {
        isOpen: isBranchModalOpen,
        onOpen: onOpenBranchModal,
        onClose: onCloseBranchModal
    } = useDisclosure();

    useEffect(() => {
        if (claimsByCompanyIdArgs) {
            setQueryFilter(claimsByCompanyIdArgs.params);
        }
    }, [claimsByCompanyIdArgs]);

    const handleKeyUp = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                resetPagination();
                getClaimsByCompanyId({ id: claimsByCompanyIdArgs?.id, params: queryFilter });
            }
        },
        [claimsByCompanyIdArgs?.id, getClaimsByCompanyId, queryFilter, resetPagination]
    );

    const handleShowClosedClaims = useCallback(() => {
        const newShowClosedClaims = !showClosedClaims;
        if (newShowClosedClaims) {
            getClaimsByCompanyId({ params: { ...queryFilter, status: "Closed" } });
            setQueryFilter({ ...queryFilter, status: "Closed" });
        } else {
            getClaimsByCompanyId({ params: { ...queryFilter, status: "Open" } });
            setQueryFilter({ ...queryFilter, status: "Open" });
        }

        setShowClosedClaims(newShowClosedClaims);
    }, [getClaimsByCompanyId, showClosedClaims, queryFilter]);

    const handleSearchCriteriaChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const originalValue = e.target.value;

            // Check if the first 4 characters are numbers
            // Then remove the "-" for the query
            // eslint-disable-next-line no-magic-numbers
            const firstFourAreNumbers = /^\d{4}/.test(originalValue.slice(0, 4));
            let queryValue = originalValue;
            if(firstFourAreNumbers) {
                // eslint-disable-next-line sonarjs/no-ignored-return
                queryValue = queryValue.replace(/-.*/, '');
            }

            setQueryFilter({ ...queryFilter, query: queryValue, page: 1 });
        },
        [queryFilter]
    );

    const handleSearchClick = useCallback(() => {
        resetPagination();
        getClaimsByCompanyId({ id: claimsByCompanyIdArgs?.id, params: queryFilter });
    }, [claimsByCompanyIdArgs?.id, getClaimsByCompanyId, queryFilter, resetPagination]);

    const handleClearSearch = useCallback(() => {
        resetPagination();
        setQueryFilter({ ...queryFilter, query: "" });
        getClaimsByCompanyId({ id: claimsByCompanyIdArgs?.id, params: { ...queryFilter, query: "" } });
    }, [claimsByCompanyIdArgs?.id, getClaimsByCompanyId, queryFilter, resetPagination]);

    const handleCloseBranchModal = useCallback(() => {
        onCloseBranchModal();
    }, [onCloseBranchModal]);

    useEffect(() => {
        if (selectedBranch) {
            getClaimsByCompanyId({
                id: selectedBranch?.crmId?.toString(),
                params: queryFilter
            });

            onCloseBranchModal();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getClaimsByCompanyId, onCloseBranchModal, selectedBranch]);

    if (isClaimsSearchError) {
        return (
            <Center>
                <Alert
                    variant="error"
                    status="error"
                    minW="18rem"
                    maxW="40rem"
                    id="noDataAlert"
                    textAlign="left"
                >
                    <AlertIcon boxSize="40px" />
                    <AlertDescription>
                        {t("errorMessages:claimsInvoicesOverview.cannotRetrieveData")}
                    </AlertDescription>
                </Alert>
            </Center>
        );
    }

    return (
        <Flex w="100%" px="1rem">
            <Flex
                flexDir="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                h="fit-content"
                w="25%"
            >
                {isShowBranchCompanyModalEnabled && (branchCompanies && branchCompanies.length > 0) && (
                    <BranchCompany
                        selectedBranch={selectedBranch}
                        setSelectedBranch={setSelectedBranch}
                        onOpenBranchModal={onOpenBranchModal}
                        getClaimsByCompanyId={getClaimsByCompanyId}
                        queryFilter={queryFilter}
                    />
                )}
            </Flex>
            <Flex w="50%" alignItems="flex-end">
                <Search
                    type="claims"
                    placeholder={t("claimsSearchPlaceholder")}
                    queryFilter={queryFilter}
                    isFetchingData={isFetchingClaims}
                    handleSearchCriteriaChange={handleSearchCriteriaChange}
                    handleKeyUp={handleKeyUp}
                    handleSearchClick={handleSearchClick}
                    handleClearSearch={handleClearSearch}
                />
            </Flex>
            <Flex justifyContent="flex-end" alignItems="flex-end" mr="1rem" w="25%">
                <Flex alignItems="center" justifyContent="flex-end" h="50px">
                    <Text mr="0.5rem">{t("openClaimsFilter")}</Text>
                    <Switch
                        id="openClaimsOnly"
                        onChange={handleShowClosedClaims}
                        isChecked={showClosedClaims}
                        tabIndex={0}
                    />
                </Flex>
            </Flex>
            {isShowBranchCompanyModalEnabled && (
                <BranchCompanyModal
                    isOpen={isBranchModalOpen}
                    onClose={handleCloseBranchModal}
                    selectedBranch={selectedBranch}
                    setSelectedBranch={setSelectedBranch}
                    branchCompanies={branchCompanies!}
                    isError={isError}
                    isLoading={isLoading}
                />
            )}
        </Flex>
    );
});

export default ClaimsSearch;
